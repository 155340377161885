import React from "react"
import { Link } from "gatsby"

export default () => (
  <footer className="footer">
    <div className="footer-main py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ul className="list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/tos">Terms of Service</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 company-info text-right">
            <p>ZeronPress Agency</p>
            <p>
              <a href="tel:+387 65 452 272">+387 65 452 272</a>
            </p>
            <p>Working hours (CET): Mon-Fri 9:00 - 17:00</p>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-copyright py-1 bg-primary text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-right">
            <span>Copyright &copy; 2020 ZeronPress</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
)
