import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

const SEO = ({ title }) => (
  <Helmet>
    <title>{title ? `${title} - ZeronPress` : `ZeronPress`}</title>
  </Helmet>
)

export default SEO

SEO.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

SEO.defaultProps = {
  title: false,
}
