import React, { useState } from "react"
import { Link } from "gatsby"
import ImgLogo from "../../assets/images/logo.png"
import HeaderHero from "./header-hero"
import { Icon } from "react-icons-kit"
import { menu } from "react-icons-kit/icomoon/menu"
import { cross } from "react-icons-kit/icomoon/cross"

export default ({ showHero }) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleButtonClick = () => {
    setShowMenu(!showMenu)
  }

  const menuClass = showMenu ? "collapsed" : "collapse"

  return (
    <header className="header">
      <div className="header__navmain">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img alt="ZeronPress logo" src={ImgLogo} className="img-fluid" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              aria-controls="header__navmain_list"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleButtonClick}
            >
              {showMenu ? (
                <Icon icon={cross} size={32} />
              ) : (
                <Icon icon={menu} size={32} />
              )}
            </button>

            <div
              className={`${menuClass} navbar-collapse header__navmain_list`}
              id="header__navmain_list"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {showHero && <HeaderHero />}
    </header>
  )
}
