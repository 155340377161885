import React from "react"
import { Link } from "gatsby"
import HeroImg from "../../assets/images/hero-2.svg"

export default () => {
  return (
    <div className="header__hero">
      <div className="container">
        <div className="header__hero__headline">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="header__hero__headline_title">
                Digital solutions for your business
              </h1>
              <p className="header__hero__headline_tagline">
                We craft custom WordPress solutions for business and startups.
                With years of experience in web development we have everything
                needed to help our clients succeed.{" "}
              </p>
              <Link className="btn btn-lg btn-secondary" to="/contact">
                Get a quote
              </Link>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <img
                src={HeroImg}
                className="img-fluid"
                alt="ZeronPress web development services"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
