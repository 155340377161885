import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../scss/app.scss"

export default ({ children, page }) => (
  <div className="wrapper">
    <Header showHero={page === "index" ? true : false} />
    {children}
    <Footer />
  </div>
)
